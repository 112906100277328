export const setMediaTrackById = (nextTrackId, tracks, setTrack) => {
    const targetTrack = tracks?.find((track) => track.id === nextTrackId);
    if (!targetTrack) {
        return;
    }
    setTrack?.(targetTrack);
};
export const updateProgram = (playOptions) => {
    if (playOptions.programStartEpochMs && playOptions.programEndEpochMs) {
        const updatedPlayOptions = { ...playOptions };
        updatedPlayOptions.program = {
            start: playOptions.programStartEpochMs,
            duration: playOptions.programEndEpochMs - playOptions.programStartEpochMs,
        };
        return updatedPlayOptions;
    }
    else {
        return playOptions;
    }
};
export const emptyFunc = () => undefined;
