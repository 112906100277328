import React, { useEffect, useState, } from 'react';
import { TextInput, StyleSheet, } from 'react-native';
import { EventKey, } from '@zattoo/playback-sdk-react-native';
import { PlayerManagerOld as PlayerManager } from '../player-manager';
import { useOsd } from '../osd-context';
export const styles = StyleSheet.create({
    inputField: {
        height: 50,
        color: '#000000',
        backgroundColor: '#ffffff',
        paddingLeft: 20,
        paddingRight: 20,
    },
    text: {
        color: '#ffffff',
        fontSize: 15,
    },
});
export const Pin = () => {
    const playerManager = PlayerManager.getInstance();
    const player = playerManager.getPlayer();
    const [requirePin, setRequirePin] = useState(false);
    const [value, setValue] = useState('');
    const { setOsd, osd, } = useOsd();
    useEffect(() => {
        if (!player) {
            return undefined;
        }
        const playerError = (event) => {
            const { error } = event;
            // @see https://docs.zattoo.com/doc/platform/topic/topic-zapi-errors
            if (error.code === 261 || // MissingPinException: Missing PIN.
                error.code === 262 || // BadPinException: Invalid PIN.
                error.code === 263 || // PinConfigException: Invalid PIN config.
                error.code === 264 // PinLockedException: PIN locked.
            ) {
                setRequirePin(true);
            }
        };
        const resetPin = () => {
            setRequirePin(false);
        };
        player.on(EventKey.PLAYER_ERROR, playerError);
        player.on(EventKey.WATCH_REQUESTED, resetPin);
        return () => {
            player.on(EventKey.PLAYER_ERROR, playerError);
            player.on(EventKey.WATCH_REQUESTED, resetPin);
        };
    }, [player]);
    if (!requirePin) {
        return null;
    }
    const onSubmitEditing = () => {
        if (osd) {
            setOsd({
                ...osd,
                pin: value,
            });
        }
    };
    const onChange = (pin) => {
        setValue(pin);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TextInput, { autoFocus: true, style: styles.inputField, value: value, onChangeText: onChange, placeholder: "Enter pin", keyboardType: "numeric", returnKeyType: "done", onSubmitEditing: onSubmitEditing })));
};
