import React from 'react';
import { StyleSheet, View, } from 'react-native';
import { PlayerManagerOld as PlayerManager } from './player-manager';
import { Track } from './track';
import { Progress } from './progress/progress';
import { Control } from './control';
import { Pin } from './pin/pin';
export const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    hidden: {
        display: 'none',
    },
});
export const OSD = ({ hide }) => {
    const playerManager = PlayerManager.getInstance();
    const player = playerManager.getPlayer();
    if (!player) {
        return null;
    }
    // @ts-expect-error for test
    window.playerManager = playerManager;
    // @ts-expect-error for test
    window.player = player;
    return (React.createElement(View, { style: hide ? styles.hidden : styles.container },
        React.createElement(Pin, null),
        React.createElement(Control, null),
        React.createElement(Track, null),
        React.createElement(Progress, null)));
};
