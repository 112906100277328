export class PlayerManagerOld {
    static instance = new PlayerManagerOld();
    // private static publicId: string | null = null;
    player = null;
    static setPublicId(publicId) {
        console.log('setPublicId', publicId);
        // this.publicId = publicId;
    }
    static getInstance() {
        if (this.instance.player) {
            return this.instance;
        }
        // if (!this.publicId) {
        //     throw new Error('publicId is not set');
        // }
        // const consoleLogger = new ConsoleLogger();
        // const playerLogger = new ChannelLogger('PlaybackSDK::Player', consoleLogger);
        // const adapterLogger = new ChannelLogger('PlaybackSDK::Adapter', consoleLogger);
        this.instance.player = null;
        // createPlayer({
        //     host: 'https://staging.zattoo.com',
        //     publicId: this.publicId,
        //     appVersion: '9.9.9',
        //     appId: 204,
        //     stepBackwardDuration: 10,
        //     stepForwardDuration: 10,
        //     logging: {
        //         playerLogger,
        //         adapterLogger,
        //     },
        // });
        return this.instance;
    }
    getPlayer() {
        return this.player;
    }
}
