import React, { useEffect } from 'react';
import { View, Text, } from 'react-native';
import { EventKey, } from '@zattoo/playback-sdk-react-native';
import { styles } from './styles';
import { PlayerManagerOld as PlayerManager } from '../player-manager';
import { Marker } from './marker';
import { useOsd } from '../osd-context';
const calculateUiPosition = (obj) => {
    return `${obj.time / (obj.duration + obj.prePadding + obj.postPadding) * 100}%`;
};
export const Progress = () => {
    const playerManager = PlayerManager.getInstance();
    const player = playerManager.getPlayer();
    const [progress, setProgress] = React.useState({
        prePadding: 0,
        postPadding: 0,
        duration: 0,
        start: 0,
        end: 0,
    });
    const [currentTime, setCurrentTime] = React.useState(0);
    const [playerDuration, setPlayerDuration] = React.useState(0);
    const { osd } = useOsd();
    const getUiPosition = (time) => {
        return calculateUiPosition({
            time,
            duration: progress.duration,
            prePadding: progress.prePadding,
            postPadding: progress.postPadding,
        });
    };
    const absoluteTime = (time) => {
        if (time < 1e9) {
            return time + (osd?.programStart ?? 0) - progress.prePadding;
        }
        return time;
    };
    const getDynamicUiPosition = (time) => {
        return getUiPosition(time - progress.start);
    };
    useEffect(() => {
        if (!player) {
            return undefined;
        }
        const mediaChanged = (event) => {
            const { prePadding, postPadding, } = event.media;
            const duration = player.duration || 0;
            setPlayerDuration(duration);
            const { programStart: start = 0, programEnd: end = 0, } = osd ?? {};
            setProgress({
                prePadding,
                postPadding,
                duration: end - start,
                start: start - prePadding,
                end: end + postPadding,
            });
        };
        const currentPositionChanged = (event) => {
            setCurrentTime(event.position);
        };
        player.on(EventKey.MEDIA_CHANGED, mediaChanged);
        player.on(EventKey.CURRENT_POSITION_CHANGED, currentPositionChanged);
        return () => {
            player.off(EventKey.MEDIA_CHANGED, mediaChanged);
            player.off(EventKey.CURRENT_POSITION_CHANGED, currentPositionChanged);
        };
    }, [player, osd]);
    if (!player) {
        return null;
    }
    const topLayers = 3;
    const bottomLayers = 3;
    return (React.createElement(View, { style: {
            ...styles.progress,
            height: styles.progress.height + ((topLayers + bottomLayers) * 15),
        } },
        React.createElement(View, { style: styles.time },
            React.createElement(Text, { style: styles.timeText }, "Progress"),
            React.createElement(Text, { style: styles.timeText }, absoluteTime(currentTime))),
        React.createElement(View, { style: {
                ...styles.bar,
                top: 15 + (topLayers * 15),
            } },
            React.createElement(Marker, { text: `t:${currentTime}`, position: getUiPosition(currentTime), layer: 0 }),
            React.createElement(Marker, { text: `pre:${progress.prePadding}`, position: getUiPosition(progress.prePadding), layer: 1 }),
            React.createElement(Marker, { text: `post:${progress.prePadding + progress.duration}`, position: getUiPosition(progress.prePadding + progress.duration), layer: 2 }),
            React.createElement(Marker, { up: true, text: `dur:${playerDuration}`, position: getUiPosition(playerDuration), layer: 0 }),
            React.createElement(Marker, { up: true, text: `s:${osd?.programStart}`, position: getDynamicUiPosition(osd?.programStart ?? 0), color: "red", layer: 1 }),
            React.createElement(Marker, { up: true, text: `e:${osd?.programEnd}`, position: getDynamicUiPosition(osd?.programEnd ?? 0), color: "red", layer: 2 }))));
};
