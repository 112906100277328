import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, } from 'react';
import { styles } from './styles';
import { createPlayer, PlayerView, } from '../platforms';
import { EventKey, PlaybackState, } from '../index';
import { PlayerStreamType, } from './interfaces';
import { playerInitialState } from './states';
import { setMediaTrackById, updateProgram, } from './utils';
export const UTVPlayerView = forwardRef(({ onEvent, host, publicId, appVersion, appId, stepForwardDuration, stepBackwardDuration, style = styles.player, }, ref) => {
    const playerInstanceRef = useRef(null);
    const playerStateRef = useRef(playerInitialState);
    const programInfo = useRef({
        preMs: 0,
        postMs: 0,
        programStartEpochMs: 0,
        programEndEpochMs: 0,
        streamType: undefined,
    });
    useEffect(() => {
        playerInstanceRef.current?.destroy();
        playerInstanceRef.current = createPlayer({
            host,
            publicId,
            appVersion,
            appId,
            stepBackwardDuration,
            stepForwardDuration,
        });
        const updateUTVPlayerState = (type, newState) => {
            const nextState = {
                ...playerStateRef.current,
                ...newState,
            };
            playerStateRef.current = nextState;
            onEvent?.({
                event: type,
                state: nextState,
            });
        };
        const updateCurrentPosition = (event) => {
            let currentMs = (event.position * 1000) + (playerStateRef.current.streamStartMs ?? 0);
            if (programInfo.current.streamType === PlayerStreamType.LIVE) {
                currentMs -= programInfo.current.programStartEpochMs;
            }
            updateUTVPlayerState(event.type, { currentMs });
        };
        const updatePlaybackState = (event) => {
            switch (event.state) {
                case PlaybackState.STOPPED:
                    updateUTVPlayerState(event.type, {
                        buffering: false,
                        canPlay: false,
                    });
                    break;
                case PlaybackState.PLAYING:
                    updateUTVPlayerState(event.type, {
                        buffering: false,
                        canPlay: false,
                    });
                    break;
                case PlaybackState.PAUSED:
                    updateUTVPlayerState(event.type, {
                        canPlay: true,
                    });
                    break;
                case PlaybackState.BUFFERING:
                    updateUTVPlayerState(event.type, {
                        buffering: true,
                    });
                    break;
                default:
                    break;
            }
        };
        const updatePlayerState = (event) => {
            const newState = {
                canForward: event.canSeekForward,
                canBackward: event.canSeekBackward,
                canPause: event.canPause,
            };
            if (event.seekableRange) {
                const { end } = event.seekableRange;
                const endRelativeTimeMs = programInfo.current.streamType === PlayerStreamType.LIVE
                    ? (end * 1000) - programInfo.current.programStartEpochMs
                    : end * 1000;
                newState.programStartMs = 0;
                newState.programEndMs = programInfo.current.programEndEpochMs - programInfo.current.programStartEpochMs;
                newState.streamStartMs = 0 - programInfo.current.preMs;
                newState.streamEndMs = endRelativeTimeMs - programInfo.current.preMs;
            }
            updateUTVPlayerState(event.type, newState);
        };
        const updateAudioTracks = (event) => {
            updateUTVPlayerState(event.type, {
                audioTracks: event.tracks,
            });
        };
        const updateSubtitlesTracks = (event) => {
            updateUTVPlayerState(event.type, {
                subtitleTracks: event.tracks,
            });
        };
        const updateMedia = (event) => {
            programInfo.current.preMs = event.media.prePadding * 1000;
            programInfo.current.postMs = event.media.postPadding * 1000;
            const playerDuration = playerInstanceRef.current?.duration ?? 0;
            const durationMs = isFinite(playerDuration) ? playerDuration * 1000 : 0;
            updateUTVPlayerState(event.type, {
                programStartMs: 0,
                programEndMs: programInfo.current.programEndEpochMs - programInfo.current.programStartEpochMs,
                streamStartMs: 0 - programInfo.current.preMs,
                streamEndMs: durationMs - programInfo.current.preMs,
            });
        };
        playerInstanceRef.current?.on(EventKey.CURRENT_POSITION_CHANGED, updateCurrentPosition);
        playerInstanceRef.current?.on(EventKey.PLAYBACK_STATE_CHANGED, updatePlaybackState);
        playerInstanceRef.current?.on(EventKey.PLAYER_STATE_CHANGED, updatePlayerState);
        playerInstanceRef.current?.on(EventKey.AVAILABLE_AUDIO_TRACKS_CHANGED, updateAudioTracks);
        playerInstanceRef.current?.on(EventKey.AVAILABLE_SUBTITLES_TRACKS_CHANGED, updateSubtitlesTracks);
        playerInstanceRef.current?.on(EventKey.MEDIA_CHANGED, updateMedia);
        return () => {
            playerInstanceRef.current?.destroy();
        };
    }, [appId, appVersion, host, onEvent, publicId, stepBackwardDuration, stepForwardDuration]);
    const play = useCallback((options) => {
        // Reset player state
        playerStateRef.current = { ...playerInitialState };
        if (!options) {
            playerInstanceRef.current?.play();
            return;
        }
        programInfo.current.programStartEpochMs = options?.programStartEpochMs ?? 0;
        programInfo.current.programEndEpochMs = options?.programEndEpochMs ?? 0;
        programInfo.current.preMs = 0;
        programInfo.current.postMs = 0;
        programInfo.current.streamType = options?.streamType;
        switch (options?.streamType) {
            case PlayerStreamType.VOD: {
                playerInstanceRef.current?.playVod(options.permissionToken, options.teasableId, options.teasableType, options);
                break;
            }
            case PlayerStreamType.LIVE: {
                const { channelId, ...playOptions } = options;
                playerInstanceRef.current?.playLive(channelId, updateProgram(playOptions));
                break;
            }
            case PlayerStreamType.REPLAY: {
                const { channelId, programId, ...playOptions } = options;
                playerInstanceRef.current?.playProgram(channelId, programId, updateProgram(playOptions));
                break;
            }
            case PlayerStreamType.RECORDING: {
                const { programId, ...playOptions } = options;
                playerInstanceRef.current?.playRecording(programId, updateProgram(playOptions));
                break;
            }
            default: {
                playerInstanceRef.current?.play();
                break;
            }
        }
    }, []);
    // const playFromStart = useCallback((params) => {
    // }, []);
    // Positive and negative numbers
    const seek = useCallback((numSteps) => {
        if (numSteps > 0) {
            playerInstanceRef.current?.seekForward(numSteps);
        }
        else if (numSteps < 0) {
            playerInstanceRef.current?.seekBackward(Math.abs(numSteps));
        }
    }, []);
    // Seek to position (relative program time ms)
    const seekTo = useCallback((positionMs) => {
        if (positionMs >= 0) {
            const newPositionMs = positionMs - (playerStateRef.current.streamStartMs ?? 0);
            playerInstanceRef.current?.seek(newPositionMs / 1000);
        }
    }, []);
    const setAudioTrack = useCallback((id) => {
        setMediaTrackById(id, playerStateRef.current.audioTracks, playerInstanceRef.current?.setAudioTrack);
    }, []);
    const setSubtitleTrack = useCallback((id) => {
        if (id === null) {
            playerInstanceRef.current?.setSubtitlesTrack(null);
            return;
        }
        setMediaTrackById(id, playerStateRef.current.subtitleTracks, playerInstanceRef.current?.setSubtitlesTrack);
    }, []);
    // const fetchNextPlay = useCallback((params) => {
    // }, []);
    const pause = useCallback(() => {
        playerInstanceRef.current?.pause();
    }, []);
    useImperativeHandle(ref, () => ({
        play,
        pause,
        seek,
        seekTo,
        setAudioTrack,
        setSubtitleTrack,
    }), [play, seek, seekTo, setAudioTrack, setSubtitleTrack, pause]);
    if (!playerInstanceRef.current) {
        return null;
    }
    return (React.createElement(PlayerView, { player: playerInstanceRef.current, style: style }));
});
