import { MediaType } from './enums';
import { generatePsid } from '../player/utils';
import { AssetType } from '../telemetry/enums/asset-type';
import { AdType } from '../player/enums/ad-type';
import { EventKey as TelemetryEventKey } from '../telemetry/enums/event-key';
export const PlayerWithPlay = (Base) => {
    return class extends Base {
        async playUrl(url, laUrl, playOptions) {
            const psid = generatePsid();
            this.triggerTelemetryNewSessionEvent(psid, AssetType.LIVE, 'external');
            await this.stop();
            const capability = this.capability;
            const trackingOptions = playOptions.telemetryEndpoint ?
                {
                    temp_telemetry_enabled: true,
                    temp_telemetry_url: playOptions.telemetryEndpoint,
                    event_pixel: '',
                    latency_measurement_interval: -1,
                } : undefined;
            const adapterRequest = {
                type: MediaType.URL,
                parameters: null,
                capability,
                watchResponse: {
                    stream: {
                        url,
                        license_url: laUrl ?? undefined,
                        watch_urls: [
                            {
                                url,
                                maxrate: 8000,
                                audio_channel: 'A',
                                license_url: laUrl ?? undefined,
                            },
                        ],
                        event_pixel: '',
                    },
                    csid: '',
                    urls: [],
                    success: true,
                    tracking: trackingOptions,
                },
                playOptions,
            };
            if (playOptions?.adsUrl) {
                adapterRequest.watchResponse.stream.ads = [{
                        // TODO: Discuss if we should add placeholder type here
                        type: AdType.PREROLL,
                        vast_url: playOptions.adsUrl,
                    }];
            }
            this.psid = psid;
            this.triggerTelemetryEvent({
                type: TelemetryEventKey.STREAM_LOADING,
                psid,
            });
            const adapterMedia = await this.adapter.load(adapterRequest);
            await this.dispatchMedia(adapterRequest, adapterMedia);
        }
    };
};
