import { AdapterCallbacks } from './adapter-callbacks';
export class AdapterCallbacksDispatcher extends AdapterCallbacks {
    logger;
    selectedAudioTrack = null;
    audioTracks = [];
    selectedSubtitlesTrack = null;
    subtitlesTracks = [];
    duration = null;
    constructor(logger) {
        super();
        this.logger = logger;
    }
    resetState() {
        this.selectedAudioTrack = null;
        this.selectedSubtitlesTrack = null;
        this.audioTracks = [];
        this.subtitlesTracks = [];
        this.duration = null;
    }
    dispatchPlayerReady = () => {
        if (this.onPlayerReady) {
            this.onPlayerReady();
        }
    };
    dispatchSelectedAudioTrackChanged = (track) => {
        this.selectedAudioTrack = track;
        this.onSelectedAudioTrackChanged?.(this.selectedAudioTrack);
    };
    dispatchPlaybackDurationChanged = (duration) => {
        this.onPlaybackDurationChanged?.(duration);
    };
    dispatchAvailableAudioTracksChanged = (audioTracks) => {
        this.audioTracks = audioTracks;
        this.onAvailableAudioTracksChanged?.(this.audioTracks);
    };
    dispatchSeeked = (position) => {
        this.onSeeked?.(position);
    };
    dispatchSelectedSubtitlesTrackChanged = (targetTrack) => {
        this.selectedSubtitlesTrack = targetTrack;
        this.onSelectedSubtitlesTrack?.(targetTrack);
    };
    dispatchAvailableSubtitleTracksChanged = (subtitleTracks) => {
        this.subtitlesTracks = subtitleTracks;
        this.onAvailableSubtitlesTracksChanged?.(subtitleTracks);
    };
    dispatchPositionChange = (time) => {
        this.onPositionChanged?.(time);
    };
    dispatchSeekableRange = (seekableRange) => {
        this.onSeekableRangeChanged?.(seekableRange);
    };
    dispatchPlaybackStateChanged = (state) => {
        this.onPlaybackStateChanged?.(state);
    };
    dispatchVideoBitrateChanged = (bitrate) => {
        this.onVideoBitrateChanged?.(bitrate);
    };
    dispatchError = (error) => {
        this.onError?.(error);
    };
}
