export class AdapterCallbacks {
    onPlayerReady = null;
    onPositionChanged = null;
    onSeekableRangeChanged = null;
    onSeeked = null;
    onAvailableSubtitlesTracksChanged = null;
    onAvailableAudioTracksChanged = null;
    onSelectedAudioTrackChanged = null;
    onSelectedSubtitlesTrack = null;
    onPlaybackStateChanged = null;
    onPlaybackDurationChanged = null;
    onVideoBitrateChanged = null;
    onError = null;
}
