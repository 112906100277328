import React, { createRef, useCallback, useState, } from 'react';
import { UTVPlayerView, } from '@zattoo/playback-sdk-react-native';
import { styles } from './styles';
import { UTVOSD } from './utv-osd';
export const PlayerApi = createRef();
export const Player = (props) => {
    const [playerState, setPlayerState] = useState(undefined);
    const onEvent = useCallback((event) => {
        setPlayerState(event.state);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(UTVPlayerView, { ref: PlayerApi, host: "https://staging.zattoo.com", publicId: props.publicId, appVersion: "9.9.9", appId: 204, stepBackwardDuration: 10, stepForwardDuration: 10, style: styles.player, onEvent: onEvent }),
        React.createElement(UTVOSD, { playerState: playerState })));
};
