import React, { useEffect, useState, } from 'react';
import { PlayerStreamType } from '@zattoo/playback-sdk-react-native/src/utv-player-redo/interfaces';
import { PlayerApi } from '../player';
import { getVodTeasers, getTerm, getStreamingOption, } from './utils';
import { ButtonsList } from '../layout/buttons-list';
import { useSessionContext } from '../authentication/context';
export const Vod = () => {
    const { session } = useSessionContext();
    const [movieTeasers, setMovieTeasers] = useState([]);
    const [episodeTeasers, setEpisodeTeasers] = useState([]);
    useEffect(() => {
        const vodPage = session?.content_pages?.vod;
        if (!vodPage || !session) {
            return;
        }
        getVodTeasers({
            pghash: session.power_guide_hash,
            public_id: vodPage,
            with_content_titles: true,
        }).then((teasers) => {
            setMovieTeasers(teasers.movieTeasers);
            setEpisodeTeasers(teasers.episodeTeasers);
        });
    }, [session]);
    const playVod = (teaser) => {
        const player = PlayerApi.current ?? null;
        if (!player) {
            return;
        }
        const term = getTerm(teaser);
        if (!term) {
            throw new Error('No term found');
        }
        const streamingOption = getStreamingOption(term);
        if (!streamingOption) {
            throw new Error('No streaming option found');
        }
        player.play({
            streamType: PlayerStreamType.VOD,
            permissionToken: term.token,
            teasableId: `${teaser.teasable_id}`,
            teasableType: teaser.teasable_type,
            drmRequired: term.drm_required,
        });
    };
    const playVodMovie = (teasableId) => {
        const movie = movieTeasers.find((teaser) => teaser.teasable_id === teasableId);
        if (!movie) {
            throw new Error('No movie found');
        }
        playVod(movie);
    };
    const playVodEpisode = (teasableId) => {
        const episode = episodeTeasers.find((teaser) => teaser.teasable_id === teasableId);
        if (!episode) {
            throw new Error('No episode found');
        }
        playVod(episode);
    };
    const makeTeaserEntries = (teasers) => {
        return teasers.map((teaser) => {
            return {
                title: `${teaser.title.slice(0, 20)}...`,
                id: `${teaser.teasable_id}`,
                metaInfo: [
                    `teasable_id: ${teaser.teasable_id}`,
                ],
            };
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ButtonsList, { onItemSelected: playVodMovie, items: makeTeaserEntries(movieTeasers), listLabel: "Vod Movies" }),
        React.createElement(ButtonsList, { onItemSelected: playVodEpisode, items: makeTeaserEntries(episodeTeasers), listLabel: "Vod Episodes" })));
};
