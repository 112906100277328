export var PlayerCueType;
(function (PlayerCueType) {
    PlayerCueType["AD"] = "AD";
})(PlayerCueType || (PlayerCueType = {}));
export var PlayerStreamType;
(function (PlayerStreamType) {
    // @TODO Add more types and check on VOD, check with design and analytics
    PlayerStreamType["LIVE"] = "LIVE";
    PlayerStreamType["REPLAY"] = "REPLAY";
    PlayerStreamType["RECORDING"] = "RECORDING";
    PlayerStreamType["TIMESHIFT"] = "TIMESHIFT";
    PlayerStreamType["VOD"] = "VOD";
})(PlayerStreamType || (PlayerStreamType = {}));
export var PlayerErrorType;
(function (PlayerErrorType) {
    // @TODO Add errors here
    PlayerErrorType["SOME_ERROR"] = "SOME_ERROR";
})(PlayerErrorType || (PlayerErrorType = {}));
export var PlayerEvents;
(function (PlayerEvents) {
    PlayerEvents["PAUSED"] = "PAUSED";
    PlayerEvents["STOPPED"] = "STOPPED";
    PlayerEvents["PLAYING"] = "PLAYING";
    PlayerEvents["PLAY_REQUESTED"] = "PLAY_REQUESTED";
    PlayerEvents["ERROR"] = "ERROR";
    PlayerEvents["INITIALIZED"] = "INITIALIZED";
})(PlayerEvents || (PlayerEvents = {}));
