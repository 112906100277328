import React, { useState } from 'react';
import { Button, View, } from 'react-native';
import { Player } from '../player';
import { Replay } from '../replay';
import { Vod } from '../vod';
import { Recording } from '../recording';
import { OSD } from '../player/osd';
import { OsdContextProvider, } from '../player/osd-context';
import { styles } from './styles';
import { Live } from '../live';
import { LogoutButton } from '../authentication/logout-button';
import { Modal, isModalSupported, } from './modal';
import { useSessionContext } from '../authentication/context';
export const Layout = () => {
    const [currentCid, setCurrentCid] = useState(null);
    const [osd, setOsd] = useState(null);
    const [menuVisible, setMenuVisible] = useState(false);
    const [osdVisible, setOsdVisible] = useState(true);
    const publicId = useSessionContext().session?.account?.public_id;
    const onChannelSelected = (cid) => {
        setCurrentCid(cid);
    };
    return (React.createElement(OsdContextProvider, { value: {
            osd,
            setOsd,
        } },
        React.createElement(View, { style: styles.layout },
            React.createElement(View, { style: styles.playerContainer }, publicId && React.createElement(Player, { publicId: publicId })),
            React.createElement(Modal, { animationType: "slide", transparent: true, visible: menuVisible, onRequestClose: () => {
                    setMenuVisible(!menuVisible);
                } },
                React.createElement(View, { style: styles.menu },
                    React.createElement(View, { style: styles.miscButtons },
                        isModalSupported &&
                            React.createElement(Button, { testID: "menu-close", onPress: () => {
                                    setMenuVisible(false);
                                }, title: "Close Menu" }),
                        React.createElement(LogoutButton, null)),
                    React.createElement(Live, { onChannelSelected: onChannelSelected }),
                    currentCid && (React.createElement(Replay, { channelId: currentCid })),
                    React.createElement(Recording, null),
                    React.createElement(Vod, null))),
            isModalSupported &&
                React.createElement(View, { style: styles.menuButton },
                    React.createElement(Button, { testID: "menu-open", hasTVPreferredFocus: true, onPress: () => {
                            setMenuVisible(true);
                        }, title: "Menu" }),
                    React.createElement(Button, { onPress: () => {
                            setOsdVisible(!osdVisible);
                        }, title: osdVisible ? 'Hide OSD' : 'Show OSD' })),
            React.createElement(OSD, { hide: !osdVisible }))));
};
