import { EventKey, PlaybackState, } from '../enums';
import { TelemetrySession } from '../../telemetry';
import { EventKey as TelemetryEventKey } from '../../telemetry/enums/event-key';
export const WithTelemetry = (Base) => {
    return class PlayerWithEvent extends Base {
        #telemetrySessions = [];
        getTelemetrySession = (psid) => {
            let telemetrySession = this.#telemetrySessions.find((session) => session.psid === psid);
            if (!telemetrySession) {
                telemetrySession = new TelemetrySession({
                    psid,
                    appId: this._playerOptions.appId,
                    appVersion: this._playerOptions.appVersion,
                    publicId: this._playerOptions.publicId,
                });
                this.#telemetrySessions.push(telemetrySession);
            }
            return telemetrySession;
        };
        triggerTelemetryNewSessionEvent(psid, assetType, assetId) {
            const telemetrySession = this.getTelemetrySession(psid);
            telemetrySession.appendEventPromise(() => {
                return Promise.resolve({
                    type: TelemetryEventKey.NEW_SESSION,
                    psid,
                    player_name: '',
                    player_ver: '',
                    player_conf_min_buf: 0,
                    asset_type: assetType,
                    asset_id: assetId,
                    player_size_w: 0,
                    player_size_h: 0,
                    screen_size_w: 0,
                    screen_size_h: 0,
                    capabilites: [],
                });
            });
        }
        triggerTelemetryEvent(payload) {
            const telemetrySession = this.getTelemetrySession(payload.psid);
            telemetrySession.appendEvent(payload);
        }
        evaluateEvent = (event) => {
            switch (event.type) {
                case EventKey.WATCH_REQUESTED: {
                    this.triggerTelemetryEvent({
                        type: TelemetryEventKey.WATCH_SENT,
                        psid: event.psid,
                    });
                    break;
                }
                case EventKey.WATCH_RECEIVED: {
                    const telemetrySession = this.getTelemetrySession(event.psid);
                    if (!event.data.tracking?.temp_telemetry_enabled) {
                        telemetrySession.disableTelemetry();
                        break;
                    }
                    telemetrySession.setEndpoint(event.data.tracking?.temp_telemetry_url ?? null);
                    telemetrySession.setCsid(event.data.csid);
                    this.triggerTelemetryEvent({
                        type: TelemetryEventKey.WATCH_RECEIVED,
                        url: event.data.stream.url,
                        license_url: event.data.stream.license_url,
                        psid: event.psid,
                    });
                    break;
                }
                case EventKey.PLAYER_READY: {
                    const telemetrySession = this.getTelemetrySession(event.psid);
                    const endpoint = this._streamInformation?.watchResponse.tracking?.temp_telemetry_url;
                    if (
                    // we had no watch received event
                    // and the endpoint was passed externally
                    !telemetrySession.hasEndpoint() &&
                        endpoint) {
                        telemetrySession.setEndpoint(endpoint);
                    }
                    else if (
                    // disable telemetry also if the endpoint
                    // was not passed externally
                    !telemetrySession.hasEndpoint() &&
                        !endpoint) {
                        telemetrySession.disableTelemetry();
                    }
                    break;
                }
                case EventKey.PLAYBACK_STATE_CHANGED: {
                    switch (event.state) {
                        case PlaybackState.PLAYING: {
                            this.triggerTelemetryEvent({
                                type: TelemetryEventKey.PLAYING,
                                psid: event.psid,
                            });
                            // todo: Cleanup of old sessions should happen on
                            // PlaybackState.STOPPED.
                            // For that we need to pass the psid to the adapter.
                            const openSessions = this.#telemetrySessions.filter((session) => session.psid !== event.psid);
                            openSessions.forEach((session) => {
                                session.close();
                            });
                            this.#telemetrySessions = this.#telemetrySessions.filter((session) => session.psid === event.psid);
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        };
        triggerFullEvent = (event) => {
            // consider to defer this off the main event loop
            this.evaluateEvent(event);
            return super.triggerFullEvent(event);
        };
        destroy() {
            this.#telemetrySessions.forEach((session) => {
                session.close();
            });
            this.#telemetrySessions = [];
            return super.destroy();
        }
    };
};
