import React, { useEffect, useState, } from 'react';
import { ReplayAvailability } from '@zattoo/zapi/lib/session/enums';
import { PlayerStreamType } from '@zattoo/playback-sdk-react-native/src/utv-player-redo/interfaces';
import { getEpg, formatDate, } from './utils';
import { ButtonsList } from '../layout/buttons-list';
import { useSessionContext } from '../authentication/context';
import { getChannels, needsDrm, } from '../live/utils';
import { useOsd } from '../player/osd-context';
import { PlayerApi } from '../player';
import { useChannels } from '../live/channels-context';
import { secToMs } from '../utils/time';
export const Replay = (props) => {
    const session = useSessionContext().session;
    const channels = useChannels().channels;
    const [epg, setEpg] = useState(null);
    const { setOsd, osd, } = useOsd();
    const channelId = props.channelId;
    useEffect(() => {
        if (!session) {
            return;
        }
        if (session.nonlive.replay_availability !== ReplayAvailability.AVAILABLE) {
            return;
        }
        getEpg(session?.lineup_hash, getChannels()).then((response) => {
            setEpg(response?.channels);
        });
    }, [session]);
    const playProgram = (programId) => {
        const player = PlayerApi.current ?? null;
        if (!player || !epg) {
            return;
        }
        const programIdNum = parseInt(programId, 10);
        const program = epg[channelId]?.find((p) => p.id === programIdNum);
        const channel = channels?.find((c) => c.cid === channelId);
        const drmRequired = channel ? needsDrm(channel) : false;
        if (program) {
            setOsd({
                programStart: program.s,
                programEnd: program.e,
                programId: program.id,
                channelId,
            });
        }
        else {
            return;
        }
        player.play({
            streamType: PlayerStreamType.REPLAY,
            channelId,
            programId: programIdNum,
            preferredAudioLanguage: 'de',
            preferredSubtitlesLanguage: 'de',
            drmRequired,
            programStartEpochMs: secToMs(program.s),
            programEndEpochMs: secToMs(program.e),
            pin: osd?.pin,
        });
    };
    if (!session || !epg) {
        return null;
    }
    const onAirProgram = epg[channelId]?.slice(-1)[0];
    const programEntries = epg[channelId]?.slice(-3).map((program) => {
        return {
            title: onAirProgram?.id === program.id
                ? `onAir: ${program?.t}`
                : program.t,
            id: String(program.id),
            metaInfo: [
                `id: ${program.id}`,
                `start: ${formatDate(program.s)}`,
                `end: ${formatDate(program.e)}`,
            ],
        };
    }) || [];
    return (React.createElement(ButtonsList, { onItemSelected: playProgram, items: programEntries, listLabel: "Replay" }));
};
