import { getReplayStream } from '@zattoo/zapi';
import { MediaType } from './enums';
import { getStreamingProtocol } from '../capability';
import { EventKey, PlaybackState, } from '../player/enums';
import { watchParamsFromCapability, watchParamsFromPlayOptions, watchParamsFromStreamType, } from './utils';
import { generatePsid } from '../player/utils';
import { AssetType } from '../telemetry/enums/asset-type';
import { EventKey as TelemetryEventKey } from '../telemetry/enums/event-key';
export const PlayerWithReplay = (Base) => {
    return class extends Base {
        get isReplay() {
            return this.mediaType === MediaType.REPLAY;
        }
        watchResponse = null;
        async playProgram(cid, programId, playOptions) {
            try {
                const psid = generatePsid();
                this.triggerTelemetryNewSessionEvent(psid, AssetType.REPLAY, String(programId));
                const capability = this.capability;
                await this.stop();
                const streamType = getStreamingProtocol(playOptions, capability);
                const dynamic_pvr = playOptions.dynamic ?? true;
                const param = {
                    cid,
                    program_id: programId,
                    // @ts-expect-error not in zapi
                    dynamic_pvr,
                    ...watchParamsFromStreamType(streamType),
                    ...watchParamsFromPlayOptions(playOptions),
                    ...watchParamsFromCapability(capability),
                };
                this.triggerEvent({
                    type: EventKey.WATCH_REQUESTED,
                    psid,
                });
                this.watchResponse = await getReplayStream(param);
                if (!this.watchResponse) {
                    throw new Error('No watch response');
                }
                this.triggerEvent({
                    type: EventKey.WATCH_RECEIVED,
                    data: this.watchResponse,
                    psid,
                });
                const adapterRequest = {
                    type: MediaType.REPLAY,
                    parameters: {
                        cid,
                        programId,
                    },
                    capability,
                    watchResponse: this.watchResponse,
                    playOptions,
                };
                this.psid = psid;
                this.triggerTelemetryEvent({
                    type: TelemetryEventKey.STREAM_LOADING,
                    psid,
                });
                const adapterMedia = await this.adapter.load(adapterRequest);
                return this.dispatchMedia(adapterRequest, adapterMedia, dynamic_pvr);
            }
            catch (error) {
                this.handleError(error);
                return Promise.reject(error);
            }
        }
        setPlayerState(newState) {
            if (!this.isReplay || this.isAdPlaying()) {
                return super.setPlayerState(newState);
            }
            return super.setPlayerState({
                canPause: this.currentState === PlaybackState.PLAYING || this.currentState === PlaybackState.BUFFERING,
                canSeekBackward: true,
                canSeekForward: this.watchResponse?.stream?.forward_seeking,
                seekableRange: this.seekableRange,
                ...newState,
            });
        }
    };
};
