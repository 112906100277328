import { MediaType } from '../../stream/enums';
export class ImaActions {
    _isAdPlaying = false;
    adapter;
    mediaType = null;
    isMutedByAds = false;
    constructor(adapter) {
        this.adapter = adapter;
    }
    isAdPlaying() {
        return this._isAdPlaying;
    }
    static getInstance() {
        return this;
    }
    loadNewSession(mediaType) {
        if (this.isMutedByAds) {
            this.adapter.unmute();
        }
        this.mediaType = mediaType;
        this._isAdPlaying = false;
    }
    onAdapterContentPauseRequested = () => {
        if (this.mediaType === MediaType.LIVE) {
            if (this.adapter.getVolume() === 0) {
                this.isMutedByAds = false;
            }
            else {
                this.isMutedByAds = true;
                this.adapter.mute();
            }
        }
        else {
            this.adapter.pause();
        }
        this._isAdPlaying = true;
    };
    onAdapterContentResumeRequested = () => {
        this._isAdPlaying = false;
        if (this.mediaType === MediaType.LIVE) {
            if (this.isMutedByAds) {
                this.adapter.unmute();
                this.isMutedByAds = false;
            }
        }
        else {
            this.adapter.play();
        }
    };
}
